import React from 'react';
import Header from './Header'; // Import the Header component

const TermsAndConditions = () => {
    const handleNavClick = (e, sectionId) => {
      e.preventDefault();
      window.location.href = `/${sectionId}`;
    };

  return (
    <div className="content-container">
      <Header handleNavClick={handleNavClick} />
      <h1 className="page-title gradient-text">Terms and Conditions</h1>
      <div className="content-body">
        <p>Welcome to WWW.OUT-OF-HOUSE.DEV. `&#123;out-of-house.dev&#125;` is a software development agency comprised solely of experienced software developers and project managers, with expertise in UX/UI design. We specialise in creating high-quality software solutions tailored to meet the unique needs of our clients.</p>
        
        <h2>1. Introduction</h2>
        <p>Your access to and use of this website is subject to the following terms and conditions. By accessing or using this website, you agree to be bound by these terms.</p>
        
        <h2>2. Use of the Website</h2>
        <p>The website is for informational purposes only. While we strive to provide accurate and up-to-date information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information contained on the website for any purpose.</p>
        
        <h2>3. Intellectual Property</h2>
        <p>All content included on this site, such as text, graphics, logos, images, and software, is the property of `&#123;out-of-house.dev&#125;` or its content suppliers and protected by UK and international copyright laws.</p>
        
        <h2>4. Limitation of Liability</h2>
        <p>`&#123;out-of-house.dev&#125;` will not be liable for any loss or damage, including indirect or consequential loss or damage, arising out of or in connection with the use of this website. However, nothing in these Terms and Conditions shall limit or exclude our liability for death or personal injury caused by our negligence, fraud, or fraudulent misrepresentation, or any other liability that cannot be excluded or limited under English law.</p>
        
        <h2>5. Changes to These Terms</h2>
        <p>We reserve the right to amend these terms and conditions at any time without prior notice. Any changes will be posted on this page and your continued use of the website will constitute your acceptance of the amended terms.</p>
        
        <h2>6. Governing Law and Jurisdiction</h2>
        <p>By accepting these Terms and Conditions, the USER and `&#123;out-of-house.dev&#125;` enter into a contract with all legal effects. Any legal action that may arise from the same shall be governed by and interpreted in accordance with the laws of England and Wales. The USER agrees that any disputes shall be resolved exclusively in the courts of England and Wales.</p>

        <h2>7. Data Retention</h2>
        <p>`&#123;out-of-house.dev&#125;` will retain your personal data only for as long as is necessary for the purposes set out in these Terms and Conditions. The retention period may vary depending on the type of data and the purpose for which it was collected. After the retention period, your personal data will be securely deleted or anonymised.</p>
        
        <h2>8. Third-Party Services</h2>
        <p>`&#123;out-of-house.dev&#125;` may use third-party service providers to assist with the operation of the website or the provision of our services. We will ensure that any third-party service providers used are compliant with UK data protection laws.</p>
        
        <h2>9. Final Provisions</h2>
        <p>The purchase orders themselves, as well as the documents generated by them, shall constitute, as applicable, originals, written and signed by the parties and shall have the legal value provided by the commercial laws in force.</p>

        <p>The parties are obliged to take the necessary measures to preserve, limit access by third parties, and establish the necessary measures to protect their access passwords and other data. The improper use of this data, as well as that which it allows of the site, will be the responsibility of each of the parties.</p>

        <p>Although `&#123;out-of-house.dev&#125;` does not require through these Terms and Conditions or other documents and means the execution of its own rights or obligations to which it is entitled, they do not constitute any assignment or waiver of such rights and obligations.</p>

        <p>In the event that any paragraph, section, or element of these Terms and Conditions is declared null and void or ineffective, this shall not affect the full content of these Terms and Conditions, which shall remain in full force and effect and shall continue to be applicable.</p>

        <p>The rights and obligations assumed by the USER when accepting the present Terms and Conditions may not be transferred by the USER to a third party without the express and written authorisation of `&#123;out-of-house.dev&#125;`.</p>

        <p>The electronic communications issued by and between `&#123;out-of-house.dev&#125;` and the USER have the same probative and legal value as written documents.</p>

        <p>These Terms and Conditions are equivalent to and an integral part of the Legal Section and all actions related to the use, operation, and access to `&#123;out-of-house.dev&#125;` are framed within them. Any contradiction that may exist between these and the Terms and Conditions and those that may be generated in promotional activities and others carried out by `&#123;out-of-house.dev&#125;`, will be resolved with prevalence and preference of the present ones.</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
